/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on the home page
        function sizes() {
          var q = 0; 
          var x = 0;

          $(".service p").each(function() {
              div = $(this);
              div.css('height', 'auto');
              
              if (div.outerHeight() > x) {
                x = div.outerHeight();
              }
          });
 
          $(".service p").each(function() {
            $(this).css('height', x);
          });

          var x = 0;

          $(".news .item").each(function() {
              div = $(this);
              div.css('height', 'auto');
              
              if (div.outerHeight() > x) {
                x = div.outerHeight();
              }
          });
          
          $(".news .item").each(function() {
            $(this).css('height', x);
          });

        }

        sizes();
        
        $(window).resize(function() {
          sizes();
        });

        $('.discount_modal .fa-times').click(function(){
            $('.discount_modal').velocity("fadeOut", { duration: 500 });
        });

        $(document).mouseup(function(e) 
        {
            var container = $(".modal_box");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) 
            {
                $('.discount_modal').velocity("fadeOut", { duration: 500 });
            }
        });

        $('.checkout_slider').on('keydown', function(e){ if (e.keyCode == 9)  e.preventDefault() });

        // JavaScript to be fired on all pages
        $('img[src$=".svg"]').each(function() {
            var $img = jQuery(this);
            var imgURL = $img.attr('src');
            var attributes = $img.prop("attributes");

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Remove any invalid XML tags
                $svg = $svg.removeAttr('xmlns:a');

                // Loop through IMG attributes and apply on SVG
                $.each(attributes, function() {
                    $svg.attr(this.name, this.value);
                });

                // Replace IMG with SVG
                $img.replaceWith($svg);
            }, 'xml');
        });

        $('.review_slider').slick({
          arrows: false,
          autoplay: true,
          autoplaySpeed: 4000,
          adaptiveHeight: false
        });

        $('.bg-slider').slick({
          arrows: false,
          dots: false,
          draggable: false,
          swipe: false,
          autoplay: true,
          autoplaySpeed: 5000,
          infinite: true,
          speed: 3000,
          fade: true,
          cssEase: 'linear'
        });

        $('.modal-koerier-big .close').click(function(){
          $('.modal-koerier-big').hide();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.liquid').imgLiquid();

        $('.datepicker').datepicker({
            dateFormat : 'dd-mm-yy',
            currentText: 'Vandaag',
            monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
            monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
            dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
            dayNamesShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
            dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],   
            minDate:1,
        }); 

        $('.datepicker_70d').datepicker({
            dateFormat : 'dd-mm-yy',
            currentText: 'Vandaag',
            monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
            monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
            dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
            dayNamesShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
            dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],   
            maxDate: '+70D',
            minDate:1,
        });

        // faq toggles
        $("div.tab").click(function() {
            $(this).toggleClass("open");
            $(this).find(".inner").slideToggle();
        });

        $('.load').velocity("fadeOut", { duration: 500 });
    
        $('.menu_overlay .close').click(function(){
          $('.blur').velocity({blur: 0}, { duration: 400 });
          $('.menu_overlay').velocity("fadeOut", { duration: 400 });
          $('.menu_overlay nav').velocity({translateZ: 0, translateX: '600px'}, {easing: 'ease', duration: 500});
          $('html').removeClass('menu-open');
        });

        $('.open_menu').click(function(e){
          e.preventDefault();
          $('.blur').velocity({blur: 5}, { duration: 500 });
          $('.menu_overlay').velocity("fadeIn", { duration: 400 });
          $('.menu_overlay nav').velocity({translateZ: 0, translateX: '0px'}, {easing: 'ease', duration: 400, delay: 100});
          $('html').addClass('menu-open');
        });

        $('.menu_overlay').click(function(e){
          if($(this).outerWidth() > 760){
            $('.blur').velocity({blur: 0}, { duration: 400 });
            $('.menu_overlay').velocity("fadeOut", { duration: 400 });
            $('.menu_overlay nav').velocity({translateZ: 0, translateX: '600px'}, {easing: 'ease', duration: 500});
            $('html').removeClass('menu-open');
          }
        });

        $('.menu_overlay .modal').click(function(e){
          e.stopPropagation();
        });

        $('.menu_overlay nav').velocity({translateZ: 0, translateX: '600px'}, {easing: 'ease', duration: 0});

        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var header = $('header.topbar');
        var header_home = $('header.topbar.home');
        var header_subpage = $('header.topbar.subpage');
        var navbarHeight = header.outerHeight();

        function hasScrolled() {
            var st = $(this).scrollTop();
            // Make sure they scroll more than delta
            if(Math.abs(lastScrollTop - st) <= delta) {
                return;
            }
            
            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st <= 50) {
              header.velocity("stop");
              header_home.velocity({ opacity: 1, translateZ: 0, backgroundColorAlpha: 0, translateY: '0px'}, {easing: 'ease', duration: 500});
              header_subpage.velocity({ opacity: 1, translateZ: 0, translateY: '0px'}, {easing: 'ease', duration: 300 });
              header.removeClass('nav_fade-in');
            } else if (st > lastScrollTop){
              // Scroll Down
              header.velocity("stop");
              header_home.velocity({ opacity: 0.5, translateZ: 0, backgroundColorAlpha: 0, translateY: '-90px'}, {easing: 'ease', duration: 300 });
              header_subpage.velocity({ opacity: 0.5, translateZ: 0, translateY: '-90px'}, {easing: 'ease', duration: 300 });
              header.removeClass('nav_fade-in');
            } else {
              // Scroll Up
              if(st + $(window).height() < $(document).height()) {
                  header.velocity("stop");
                  header_home.velocity({opacity: 1, backgroundColorAlpha: 1, translateZ: 0, translateY: '0px'}, {easing: 'ease', duration: 300});
                  header_subpage.velocity({ opacity: 1, translateZ: 0, translateY: '0px'}, {easing: 'ease', duration: 300 });
                  header.addClass('nav_fade-in');
              }

            }
            
            lastScrollTop = st;
        }
        $(window).scroll(function(event){
            didScroll = true;
        });

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 100);

        var checkout = $(".checkout_slider");
        var dots = $('.slick-dots');
      
        function checkout_slider_format() {
          var wrap = checkout.find('.wrapper');
          var ts = checkout.find('.slide').length;
          var tw = checkout.outerWidth() * ts;

          wrap.css('width', tw);

          checkout.find('.slide').each(function(){
            $(this).css('width', 100 / ts + '%');
          });
          
        } checkout_slider_format();
        
        setTimeout(function(){
          checkout.css('height', checkout.find('.slide[data-num="1"]').outerHeight());
        }, 100);

        $(window).resize(function(){
          checkout_slider_format();
        });

        $('.goTo1').on('click', function(){
          checkout.find('active').removeClass('active');
          checkout.find('.slide[data-num="1"]').addClass('active');
          
          dots.find('li.active').removeClass('active');
          dots.find('li[data-num="1"]').addClass('active');

          $(".checkout_slider .wrapper").velocity({translateX: '0%'}, {duration:600});
          checkout.velocity({height: checkout.find('.slide[data-num="1"]').outerHeight()}, {duration:600});

          $("html, body").velocity("scroll", {duration:600, offset: (checkout.offset().top - 80)});
        });

        $('.goTo2').on('click', function(){
          checkout.find('.slide.active').removeClass('active');
          checkout.find('.slide[data-num="2"]').addClass('active');

          dots.find('li.active').removeClass('active');
          dots.find('li[data-num="2"]').addClass('active');

          $(".checkout_slider .wrapper").velocity({translateX: '-33.33%'}, {duration:600});
          checkout.velocity({height: checkout.find('.slide[data-num="2"]').outerHeight()}, {duration:600});

          $("html, body").velocity("scroll", {duration:600, offset: (checkout.offset().top - 80)});
        });

        $('.goTo3').on('click', function(){
          checkout.find('.slide.active').removeClass('active');
          checkout.find('.slide[data-num="3"]').addClass('active');

          dots.find('li.active').removeClass('active');
          dots.find('li[data-num="3"]').addClass('active');

          $(".checkout_slider .wrapper").velocity({translateX: '-66.66%'}, {duration:600});
          checkout.velocity({height: checkout.find('.slide[data-num="3"]').outerHeight()}, {duration:600});

          $("html, body").velocity("scroll", {duration:600, offset: (checkout.offset().top - 80)});
        });

        $(".button[name='submit']").on('click', function(e){
          if($('.form').valid() === false) {
            checkout.find('.slide.active').removeClass('active');
            checkout.find('.slide[data-num="1"]').addClass('active');
           
            dots.find('li.active').removeClass('active');
            dots.find('li[data-num="1"]').addClass('active');

            $(".checkout_slider .wrapper").velocity({translateX: '0%'}, {duration:600});
            checkout.velocity({height: checkout.find('.slide[data-num="1"]').outerHeight()}, {duration:600});
            $("html, body").velocity("scroll", {duration:600, offset: (checkout.offset().top - 80)});
          }
        });

        $(".form").validate({
          focusCleanup: true,
          invalidHandler: function(event, validator) {
            // 'this' refers to the form
            var errors = validator.numberOfInvalids();
            if (errors) {
              $(".basic-error").show();
              $(".system-error").hide();
            } else {
              $(".basic-error").hide();
            }
          }
        });

        $('body, html').scrollTop(0);
        $(".form_multi").validate({focusCleanup: true});

        $('.notab input').on('keydown', function(e){ if (e.keyCode == 9)  e.preventDefault() });

      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
